import React from 'react';
import Layout from '../components/Layout';

import Dibujo3d from '../../static/images/solutions/linea_de_lavado/c5.jpg'
import LineaLavado from '../../static/images/solutions/linea_de_lavado/Automatic_Detergent_Dosing_System_s.png'
import AhorroAgua from '../../static/images/solutions/linea_de_lavado/Water_Saving_System_s.png'
import Subproductos from '../../static/images/solutions/linea_de_lavado/Collection_System_s.png'
import Integrado from '../../static/images/solutions/linea_de_lavado/Functional_Units-IAS1_s.png'
import Finos from '../../static/images/solutions/linea_de_lavado/Functional_Units-PURIFINES_Washing_System_s.png'

const Service_one = ({location}) => {
    const {state = {}} = location;
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{
                            backgroundImage: `url(../images/solutions/linea_de_lavado/5.jpg)`,
                        }}
                    >
                        <h1>Unidades de limpieza funcional</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Antecedentes</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                La industria del reciclaje de botellas de PET se está moviendo gradualmente hacia la
                                estandarización y la madurez. El proceso de lavado y la configuración razonables pueden
                                realizar la automatización e inteligencia del sistema de reciclaje. Los siguientes
                                sistemas auxiliares de la línea de lavado pueden mejorar la calidad del producto final,
                                reducir la mano de obra, y obtener el mayor valor agregado. Se puede seleccionar de
                                acuerdo a diferentes demandas y aplicaciones
                            </p>
                        </div>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Sistema de dosificación automático</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                El sistema de dosificación automático puede monitorear la concentración del agente en la
                                línea de limpieza en funcionamiento en tiempo real e inyectar el agente de limpieza
                                preparado de manera regular y cuantitativa de acuerdo con los requisitos de calidad de
                                las escamas de la botella de salida, lo que reduce las operaciones manuales en línea y
                                garantiza la seguridad de la producción.
                                El sistema automático de dosificación de detergente se aplica a la sección de prelavado
                                y lavado en caliente de las botellas. A través de la bomba dosificadora, la válvula de
                                seguridad y otros dispositivos, el programa preestablecido puede preparar los productos
                                químicos de lavado en la proporción del formulario y dosificarlos automáticamente en las
                                máquinas necesarias.
                                Mediante el control en línea en tiempo real de las concentraciones de productos
                                químicos, el sistema dosifica automáticamente los productos químicos de lavado
                                preparados de acuerdo con los requisitos de calidad de las escamas rPET y / o la
                                limpieza de la materia prima y ahorra mano de obra ".
                            </p>
                            <br/>
                            <img
                                src={LineaLavado}/>
                        </div>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Sistema de ahorro de agua en línea</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                La línea de lavado producirá una gran cantidad de agua residual en todas las unidades de
                                prelavado de botellas, lavado en caliente de escamas de botellas, enjuague y otras
                                unidades. El sistema de ahorro de agua en línea recolecta, procesa y filtra las aguas
                                residuales generadas para lograr la reutilización en línea. Reduce en gran medida el
                                consumo de agua de la línea de lavado.
                                Más del 50% del agua de enjuague usada se puede procesar directamente en línea para
                                obtener la calidad del agua pura y reutilizarse en el proceso de enjuague, lo que ahorra
                                una gran cantidad de recursos hídricos.
                                <br/>
                                Química A
                                <br/>
                                Químico B
                                <br/>
                                Agua de enjuagüe usada
                                <br/>
                                Tanque de agua
                                <br/>
                                Agua tratada para su reutilización
                                <br/>
                                Tanque de lodo
                                <br/>
                                Acceso al pozo de aguas residuales
                            </p>
                            <br/>
                            <img
                                src={AhorroAgua}/>
                        </div>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Sistema de recogida de subproductos</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                El proceso de reciclaje de lavado de las botellas de PET produce una gran cantidad de
                                tapones, aros, etiquetas y polvos separados. Estos materiales auxiliares son
                                recolectados y procesados por segunda vez como materias primas para ingresar al mercado
                                de ventas para incrementar el valor agregado del producto.
                                Este sistema puede transportar etiquetas, tapones de botellas, polvos, etc. en el
                                proceso de pelado y lavado de películas desde varios puntos de descarga hasta la
                                estación de empaque centralizada a través del sistema de reciclaje centralizado de
                                materiales auxiliares, reduciendo el manejo manual en sitio y las operaciones de
                                montacargas.
                            </p>
                            <br/>
                            <img
                                src={Subproductos}/>
                        </div>
                    </div>


                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Sistema de automatización integrado</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                Utilizando la Solución de Automatización Integrada Inteligente (IAS) para proporcionar
                                gestión automatizada de la línea de producción para plantas de limpieza y reciclaje de
                                botellas de PET, control centralizado de la información del equipo, eliminación de
                                anormalías en tiempo real y elevar estabilidad de producción y seguridad de la planta.
                            </p>
                            <br/>
                            <img
                                src={Integrado}/>
                        </div>
                    </div>

                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Sistema de lavado de finos</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                El sistema de limpieza y lavado de los finos es un proceso desarrollado para limpiar y
                                reciclar botellas de PET de mayor y mediano tamaño, así como también al procesamiento de
                                reciclaje centralizado de polvo y escamas de botellas de PET. Es un equipo de tecnología
                                avanzada y amigable con el medio ambiente, para el procesamiento de desechos mezclados
                                de PET. El sistema consta de una lavadora de finos y su conjunto de equipos envuelto del
                                proceso de reciclaje, lavado y trituración de botellas de PET, el fino de PET producido
                                en el proceso de deshidratación centrífuga y los materiales del piso en el proceso de
                                lavado. Después de la separación, lavado, secado y otras tecnologías de proceso del
                                sistema, el índice de finos de PET terminado puede alcanzar el estándar de hilado de
                                fibra cortada y puede aplicarse directamente en la máquina. El propósito de este sistema
                                es aumentar la tasa de reciclaje de los productos terminados en las fábricas de PET
                                reciclado y aumentar el valor agregado de los productos.
                                La máquina de lavado PURIFINES se utiliza para separar las partículas finas (2-3% del
                                peso total) producidas en el proceso de producción de escamas limpias de PET. Esto, sin
                                la acción del agua salada concentrada, los restos de etiquetas, fibra y arena logran
                                separarse para obtener micro pellets de PET puro.
                            </p>
                            <br/>
                            <img
                                src={Finos}/>
                        </div>
                    </div>

                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Opciones de modelo</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">

                            <img src={Dibujo3d} style={{width: "100%"}}/>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Service_one;
